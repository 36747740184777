import React, { Component } from "react";

import { NavLink } from "react-router-dom";

// reactstrap components
import {
  NavItem,
  Nav,
  Jumbotron,
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  CardHeader
} from "reactstrap";

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div className="content">
        <Row />
        <Row>
          <Col />
          <Col>
            <Card>
              <CardHeader>
                <CardTitle>
                  Welcome to the PowerShades / Wideband Labs Manufacturing API!
                </CardTitle>
              </CardHeader>
              <CardBody>
                <p>NODE_ENV={process.env.NODE_ENV}</p>
                <br />
                <p>REACT_APP_API_BASE_URL={process.env.REACT_APP_API_BASE_URL}</p>
                <br />
                <Col />
                <Nav navbar className="ml-auto">
                  <NavItem>
                    <NavLink
                      to="/admin/dashboard"
                      className="nav-link text-primary"
                    >
                      <i className="tim-icons icon-minimal-right" />
                      To MFG Dashboard!
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardBody>
            </Card>
          </Col>
          <Col />
        </Row>
        <Row />
      </div>
    );
  }
}

export default Home;
