import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router";
import { FetchOrders } from "./views/FetchOrders";
import AdminLayout from "layouts/Admin/Admin.js";
import AuthLayout from "layouts/Auth/Auth.js";
import Home from "./views/Home.js";
import ProtectedRoute from "./components/ProtectedRoute";
import Unauthorized from './components/Unauthorized';
import MfgApiService from "./MfgApiService";
import useInterval from "./utils/useInterval";
//import './custom.css'

//export default class App extends Component {
export default function App() {
    const history = useHistory(); // for logout
   

    useEffect(() => {
        document.body.classList.toggle("white-content");
    }, []);

    // set the refresh token timer. We will allow constant refeshing because this API and it's views
    // will be on 24/7 monitors
    useInterval( async () => {

        const tokenString = localStorage.getItem('auth.refresh_token');
        if (tokenString !== null) {
            const refreshToken = JSON.parse(tokenString);
            if (refreshToken !== null) {
                MfgApiService.refreshToken(refreshToken)
                    .then(response => {
                        console.log("refresh_token", response);
                        if (response.ok) {
                            response.json().then(data => {
                                console.log("data", data);
                                localStorage.setItem('auth.access_token', JSON.stringify(data.access_token));
                                localStorage.setItem('auth.refresh_token', JSON.stringify(data.refresh_token));
                                localStorage.setItem('auth.authenticated', true);
                                console.log("new tokens saved");
                            })
                        } else {
                            // clear local storage and log them out
                            localStorage.removeItem('auth.access_token');
                            localStorage.removeItem('auth.refresh_token');
                            localStorage.removeItem('auth.authenticated');
                            
                            history.push("/login");
                        }
                    })
                    .catch(error => {
                        console.error("refresh_token error", error)
                        // clear local storage and log them out
                        localStorage.removeItem('auth.access_token');
                        localStorage.removeItem('auth.refresh_token');
                        localStorage.removeItem('auth.authenticated');

                        history.push("/login");
                    }                        
                );
            }
        }
    },
    //20000);  // when testing
     60000 * 5); // refresh token every 5 minutes

    return (
        <Switch>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route path="/admin" render={props => <AdminLayout {...props} />} />
            <ProtectedRoute exact path="/fetch-orders" component={FetchOrders} />
            <Route exact path="/home" component={Home} />
            <Route exact path="/unauthorized" component={Unauthorized} />



            <Redirect from="/login" to="/auth/login" />
            <Redirect from="/" to="/auth/login" />

        </Switch>

    );
}

